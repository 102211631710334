"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonToTsv = exports.tsvToJson = void 0;
const csv_parse_1 = require("csv-parse");
const csv_stringify_1 = require("csv-stringify");
async function tsvToJson(tsv) {
    return process(tsv, (0, csv_parse_1.parse)({
        delimiter: '\t',
        columns: true,
        trim: true,
        cast: true,
    }));
}
exports.tsvToJson = tsvToJson;
async function jsonToTsv(json, { columns = [] }) {
    const processed = await process(json, (0, csv_stringify_1.stringify)({
        delimiter: '\t',
        header: true,
        columns: toColumns(columns),
    }));
    return processed.join('').toString();
}
exports.jsonToTsv = jsonToTsv;
function toColumns(columns) {
    return columns.map((c) => typeof c === 'string' ? { key: c, header: c } : c);
}
function process(data, processor) {
    return new Promise(async (res, rej) => {
        let results = [];
        // @ts-ignore: this works as expected, but ts does not think so
        processor.on('readable', async () => {
            let row;
            // @ts-ignore: this works as expected, but ts does not think so
            while ((row = await processor.read())) {
                results.push(row);
            }
        });
        // @ts-ignore: this works as expected, but ts does not think so
        processor.on('error', rej);
        // @ts-ignore: this works as expected, but ts does not think so
        processor.on('end', () => {
            res(results);
        });
        if (typeof data === 'string') {
            // Write TSV data to Parse
            // @ts-ignore: this works as expected, but ts does not think so
            processor.write(data);
        }
        else if (typeof data === 'object') {
            // Stringify each row of JSON
            for (const row of data) {
                // @ts-ignore: this works as expected, but ts does not think so
                processor.write(row);
            }
        }
        // @ts-ignore: this works as expected, but ts does not think so
        processor.end();
    });
}
