import React from 'react';
import { array, string } from '@ordaos/util';
import useStatelyProp from "../../hooks/use-stately-prop.js";
import * as Icon from "../Icon/index.js";
import cx from "../../lib/classnames.js";
import Button from "../Button/index.js";
import Label from "../Label/index.js";
import ObjectField from "../ObjectField/index.js";
import Toggle from "../Toggle/index.js";
import * as Icons from "../Icon/index.js";
import * as s from './ArrayField.module.scss';
function ArrayField({ ...props }) {
    const [checked, setChecked] = useStatelyProp(props.toggleable !== 'unchecked');
    const [values, setValues] = useStatelyProp(props.value ?? [], JSON.stringify);
    const [expanded, setExpanded] = useStatelyProp(props.expanded);
    const specificClass = props.label
        ? `array-field--${string.dashify(props.label)}`
        : '';
    const hasHeader = !!props.label || !!props.default;
    const atMin = values.length > 0 && values.length <= props.minItems;
    const atMax = values.length > 0 && values.length >= props.maxItems;
    return (React.createElement("div", { className: cx(s, 'array-field', {}, specificClass) },
        hasHeader && (React.createElement("div", { className: cx(s, 'array-field--title') },
            React.createElement(Label, null, props.label),
            props.toggleable && (React.createElement(Toggle, { checked: checked, onClick: () => {
                    if (props.onToggle) {
                        props.onToggle(!checked);
                    }
                    return setChecked(!checked);
                } })),
            props.default && !props.noAdd && (React.createElement(Button, { disabled: atMax, icon: true, onClick: addItem },
                React.createElement(Icons.Add, null))),
            props.info && (React.createElement("span", { className: cx(s, 'array-field--info') },
                React.createElement(Icons.Info, { text: props.info }))),
            props.caption && (React.createElement("span", { className: 'ord-u-type-preset-12' }, props.caption)),
            values.length > 0 && !props.noRemove && (React.createElement(Button, { icon: true, onClick: () => {
                    if (props.minItems) {
                        onChange(values.slice(0, props.minItems));
                    }
                    else {
                        onChange([]);
                    }
                } },
                React.createElement(Icons.ClearAll, null))))),
        React.createElement("ul", { className: cx(s, 'array-field--input-list') }, values.map((value, i) => {
            return (React.createElement("li", { key: `array-field-item-${i}`, className: cx(s, 'array-field--input-list--item'), onMouseEnter: () => props.onItemHover?.(value, i), onMouseLeave: () => props.onItemHover?.(value, null), onClick: () => props.onItemFocus?.(value, i) },
                React.createElement(ObjectField, { value: value, key: `array-field-object-${i}`, onChange: (next) => onChange(array.replaceAt(values, i, next)), onBlur: (next) => onChange(array.replaceAt(values, i, next)) }, (objProps) => {
                    const itemForm = props.children({
                        i,
                        ...objProps,
                    });
                    const collapsed = props.collapsible && i !== expanded;
                    const label = typeof props.collapsible === 'function'
                        ? props.collapsible(value, i)
                        : value?.toString();
                    return (React.createElement("div", { className: cx(s, 'array-field--input-list--item-content') },
                        props.collapsible && (React.createElement("div", { onClick: () => {
                                if (expanded !== i) {
                                    setExpanded(i);
                                }
                                else {
                                    setExpanded(null);
                                }
                            }, className: cx(s, 'array-field--input-list--item-collapsible', { expanded: !collapsed }) },
                            React.createElement("span", { className: cx(s, 'array-field--input-list--item-collapsible-label') }, label),
                            React.createElement("div", { className: cx(s, 'array-field--input-list--item-collapsible-actions') },
                                collapsed ? (React.createElement(Icon.Show, null)) : (React.createElement(Icon.Hide, null)),
                                !props.noRemove && (React.createElement(Button, { disabled: atMin, icon: true, onClick: () => {
                                        removeItem(i);
                                        setExpanded(null);
                                    } },
                                    React.createElement(Icons.Subtract, null)))))),
                        !collapsed && (React.createElement("div", { onClick: () => {
                                return props.onItemFocus?.(value, i);
                            }, className: cx(s, 'array-field--input-list--item-form', {
                                collapsible: props.collapsible,
                            }) },
                            itemForm,
                            !props.collapsible &&
                                !props.noRemove && (React.createElement(Button, { icon: true, disabled: atMin, onClick: () => removeItem(i) },
                                React.createElement(Icons.Subtract, null)))))));
                })));
        }))));
    function onChange(values) {
        setValues(values);
        return props.onChange?.(values);
    }
    function addItem() {
        if (!props.default)
            return;
        if (values.length < props.minItems) {
            setExpanded(props.minItems);
            onChange([
                ...values,
                ...array
                    .zeros(props.minItems - values.length)
                    .map((_) => props.default),
            ]);
            return;
        }
        setExpanded(values.length);
        onChange([...values, props.default]);
    }
    function removeItem(i) {
        onChange(array.removeAt(values, i));
        return props.onRemove?.(values[i], i);
    }
}
export default ArrayField;
