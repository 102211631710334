import { useState, useEffect } from 'react';
function useStatelyProp(value, prepare = (_) => _, preventUpdate = () => false) {
    const comparable = prepare(value);
    const [stateValue, setStateValue] = useState(value);
    useEffect(() => {
        if (comparable !== prepare(stateValue) && !preventUpdate(value))
            setStateValue(value);
    }, [comparable]);
    return [stateValue, setStateValue];
}
export default useStatelyProp;
